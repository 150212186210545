.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.container,
.container > div {
    width: 100%;
}
