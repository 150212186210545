.root {
    height: 100%;

    > div[role='progressbar'] {
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.appbar {
    height: 60px;
}

.content {
    height: calc(100% - 60px);
}

@media (min-width: 600px) {
    .root {
        display: flex;
        height: 100%;
    }

    .appbar {
        width: 72px;
        height: 100%;
        position: relative;
        z-index: 2;
        display: block;
    }

    .content {
        flex: 1;
        position: relative;
        z-index: 1;
        max-width: 100%;
        height: 100%;
    }
}

body[class='darkmode'] {
    .root {
        background-color: var(--dm-colors-primary);
    }
}
