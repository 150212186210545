.containerFull {
    height: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    background-color: #fff;
}

.containerFull > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.containerFull span.errorMsg,
.containerFull span.errorMsgDetails {
    display: block;
    text-align: center;
    color: var(--colors-error);
    font-family: var(--font-family-secondary);
    font-size: 20px;
}

.containerFull span.errorMsgDetails {
    margin-bottom: 30px;
}

.containerBanner {
    background-color: var(--colors-error);
    font-family: var(--font-family-secondary);
    font-size: 12px;
    color: var(--colors-white);
    padding: 5px 15px;
}

.containerBanner span {
    padding-right: 10px;
}

.containerBanner img {
    width: 16px;
    height: 16px;
    vertical-align: bottom;
}
