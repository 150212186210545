.buttonContainer {
    position: relative;
    display: inline-block;

    .button {
        font-family: var(--button-font-family);
        text-transform: none;
        font-size: var(--button-font-size);
        padding: 6px 16px;
        font-weight: 600;

        &.text {
            border-radius: 0;
            padding-left: 12px;
            padding-right: 12px;
            color: var(--button-text-color);
            background-color: var(--button-text-background);
            border-bottom: 2px solid var(--button-text-color);
            font-weight: 700;

            &:hover {
                color: var(--button-text-hover-color);
                background-color: var(--button-text-hover-background);
                border-bottom: 2px solid var(--button-text-hover-color);
            }

            &:disabled {
                color: var(--button-text-disabled);
                border-color: var(--button-text-disabled);
            }

            &.success,
            &.success:hover,
            &:disabled.success {
                background: var(--colors-success);
                border: 1px solid var(--colors-success);
                pointer-events: none;
            }
        }

        &.contained {
            border-radius: 20px;
            color: var(--button-contained-color);
            background: linear-gradient(to bottom, var(--button-gradient-light) 0%, var(--button-gradient-dark) 100%);

            &:hover {
                background: linear-gradient(
                    to bottom,
                    var(--button-gradient-light) 0%,
                    var(--button-contained-hover-background) 100%
                );
            }

            &:active {
                background-color: var(--button-contained-hover-background);

                span:last-child {
                    background-color: transparent;
                }
            }

            &:disabled {
                background: var(--button-contained-disabled-background);
                border: 1px solid var(--button-contained-disabled-background);
            }

            &.success,
            &.success:hover,
            &:disabled.success {
                background: var(--colors-success);
                border: 1px solid var(--colors-success);
                pointer-events: none;
            }
        }

        &.outlined {
            border-radius: 3px;
            color: var(--button-contained-color);
            background-color: var(--button-outlined-background);
            border: 1px solid var(--button-outlined-background);
            padding: 2px 12px;
            min-width: auto;
            font-weight: 500;

            &:hover {
                color: var(--button-contained-color);
                background-color: var(--button-contained-disabled-background);
                border: 1px solid var(--button-outlined-background);
            }

            &:active {
                background-color: var(--button-outlined-active-background);

                span:last-child {
                    background-color: transparent;
                }
            }

            &:disabled {
                background: var(--button-contained-disabled-background);
                border: 1px solid var(--button-contained-disabled-background);
            }

            &.success,
            &.success:hover,
            &:disabled.success {
                background: var(--colors-success);
                border: 1px solid var(--colors-success);
                pointer-events: none;
            }
        }

        &[class*='create'] {
            border-radius: 0;
            background-color: var(--button-contained-background);
            border: 1px solid var(--button-contained-background);
            text-indent: -99999px;
            width: 33px;
            height: 33px;
            min-width: 33px;
            min-height: 33px;
            padding: 0;
            position: relative;
        }

        svg {
            font-size: 18px;
        }

        &.withLeftIcon img,
        &.withLeftIcon svg {
            margin-right: 8px;
        }

        &.withRightIcon img,
        &.withRightIcon svg {
            margin-left: 8px;
        }
    }

    .buttonText {
        position: relative;
        top: 1px;
    }

    > span {
        align-items: center;
    }
}

.loader {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;

    > div[role='progressbar'] {
        position: relative;
        top: 0;
        left: 0;
        transform: initial;
    }
}

body[class='darkmode'] {
    .buttonContainer .button.text {
        color: var(--dm-colors-accent);
    }
}
