@import '../../assets/css/mixins.scss';

#root {
    border: 10px solid red;
    background: blue;
}

.wrapper {
    html body & {
        font-family: hind, sans-serif;
        line-height: 28px;
    }

    display: flex;
    justify-content: center;
    overflow-y: scroll;
    height: 100%;
    @include scrollbars;

    .container {
        padding-bottom: 100px;
        max-width: 650px;
        position: relative;

        li {
            padding-bottom: 25px;
        }

        .goHome {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    a {
        &:link,
        &:visited {
            color: rgb(0, 110, 255);
            text-decoration: none;
        }
        &:hover {
            color: rgb(0, 69, 160);
            text-decoration: underline;
        }
        strong {
            display: inline-block;
            width: 85px;
        }
    }

    h2 span {
        font-size: 14px;
    }
}
