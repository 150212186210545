div {
    &.loader {
        height: 9px;
        background-color: var(--loader-linear-background);
        width: 50%;
        margin: 0 auto;

        div {
            background-color: var(--loader-linear-color);
        }
    }
}
