@mixin scrollbars-active {
    &::-webkit-scrollbar-track {
        box-shadow: var(--scroll-track-shadow);
    }
    &::-webkit-scrollbar-thumb {
        background-color: var(--scroll-thumb-hover-background);
        border: 1px solid var(--scroll-thumb-hover-border);
        border-radius: 3px;
    }
    body[class='darkmode'] & {
        &::-webkit-scrollbar-thumb {
            background-color: var(--scroll-thumb-hover-background);
            border: 1px solid var(--scroll-thumb-hover-border);
            border-radius: 3px;
        }
    }
}

@mixin scrollbars {
    scrollbar-color: var(--scroll-thumb-hover-background) var(--scroll-thumb-background);
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        box-shadow: none;
    }
    &::-webkit-scrollbar-thumb {
        background-color: var(--scroll-thumb-background);
        border: 1px solid var(--scroll-thumb-border);
        border-radius: 3px;
    }
    body[class='darkmode'] & {
        &::-webkit-scrollbar-thumb {
            background-color: var(--dm-colors-primary);
            border: 1px solid var(--dm-colors-primary);
            border-radius: 3px;
        }
    }
    &:hover {
        @include scrollbars-active;
    }
}

@mixin scrollbars-nohover {
    @include scrollbars;
    @include scrollbars-active;
}

@mixin formSelectBase {
    border-radius: 4px 4px 0 0;
    width: 100%;

    > div::before,
    > div::after {
        display: none;
    }
}

@mixin formSelectMenu {
    background-color: var(--colors-tertiary-accent);
    body[class='darkmode'] & {
        background-color: var(--dm-colors-secondary);
    }

    .selectMenu {
        border-radius: 4px 4px 0 0;
        border-bottom: 2px solid var(--colors-secondary-light);
        padding: 6px 32px 6px 8px;

        &,
        div,
        p {
            font-family: var(--font-family-base);
            font-size: 13px;
        }

        div {
            font-size: 13px;
            font-family: var(--font-family-base);

            img {
                max-height: 16px;
            }
        }

        div[data-qa='ChipStatus'] p::before,
        div[data-qa='ChipStatus'] p::after {
            width: 12px;
            height: 12px;
            margin-top: -6px;
        }

        &[role='button'] {
            padding: 6px 32px 6px 8px;
            color: var(--colors-secondary);
            background-color: var(--colors-tertiary-accent);
            border-bottom: 2px solid var(--colors-secondary-light);

            &:hover,
            &:focus {
                color: var(--colors-primary);
                border-bottom: 2px solid var(--colors-primary);

                p {
                    color: var(--colors-primary);
                }
            }
        }

        body[class='darkmode'] & {
            &[role='button'] {
                background-color: var(--dm-colors-secondary);
                p {
                    color: var(--dm-colors-accent);
                }
                &:hover,
                &:active,
                &:focus {
                    color: var(--dm-colors-white);
                    border-bottom-color: var(--dm-colors-accent);
                }
            }
        }
    }
}

@mixin formSelectList {
    border-radius: 0;
    margin-top: 15px;

    ul[role='listbox'] {
        padding: 0;
    }

    li.menuItem {
        padding: 5px 20px;
        font-size: 13px;
        font-family: var(--font-family-secondary);

        img {
            max-height: 16px;
        }

        p::before,
        p::after {
            width: 12px;
            height: 12px;
            margin-top: -6px;
        }
    }

    li.menuItem.menuItemSelected {
        background-color: var(--colors-tertiary-accent);
    }

    body[class='darkmode'] & {
        background-color: var(--dm-colors-secondary);
        li.menuItem.menuItemSelected {
            background-color: var(--dm-colors-tertiary);
            p {
                color: var(--dm-colors-accent);
            }
        }
    }
}

@mixin cardsRemoveIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    max-width: 34px;
    justify-content: center;
    padding: 0 5px;

    button[class*='contained'] {
        border-radius: 50%;
        min-height: 24px;
        min-width: 24px;
        svg {
            width: 24px;
            height: 16px;
        }
    }

    @media (min-width: 1200px) {
        max-width: 0;
        padding: 0;
    }
}

@mixin cardsRemoveIconAnimation {
    max-width: 34px;
    transition: max-width 0.15s ease-out;
    padding: 0 5px;
}

@mixin notForPrint {
    @media print {
        display: none;
    }
}
