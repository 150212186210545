:root {
    /*
      * Typography
      * ======================================================================== */
    --font-family-base: 'Hind', serif;
    --font-size-base: 14px;
    --font-family-secondary: 'Barlow', serif;

    /*
      * Colors
      * ======================================================================== */
    --colors-white: #ffffff;
    --colors-black: #000000;
    --colors-primary: #181163;
    --colors-primary-accent: #0077cc;
    --colors-primary-light: #4a9eda;
    --colors-primary-background: #f4f9fd;
    --colors-secondary: #292929;
    --colors-secondary-accent: #777777;
    --colors-secondary-light: #696969;
    --colors-tertiary: #f1f1f1;
    --colors-tertiary-accent: #e4e7eb;
    --colors-error: #d0021b;
    --colors-success: #7ed321;
    --colors-disabled: #707070;

    /*
      * Buttons
      * ======================================================================== */
    --button-font-family: 'Hind', sans-serif;
    --button-font-size: 14px;
    --button-text-color: #242424;
    --button-text-background: transparent;
    --button-text-hover-color: #006ab5;
    --button-text-hover-background: transparent;
    --button-text-disabled: #c5c5c5;
    --button-contained-color: #ffffff;
    --button-contained-background: #0077cc;
    --button-contained-hover-color: #ffffff;
    --button-contained-hover-background: #4a9eda;
    --button-contained-disabled-background: #cacaca;
    --button-outlined-background: #383838;
    --button-outlined-hover-background: #6b6b6b;
    --button-outlined-active-background: #9a9a9a;
    --button-dark-background: #181163;
    --button-gradient-light: #0176cb;
    --button-gradient-dark: #015b9b;

    /*
      * Chips
      * ======================================================================== */
    --chips-active: #7ed321;
    --chips-error: #d0021b;
    --chips-black: #000000;
    --chips-deleted: #000000;
    --chips-inactive: #aeaeae;
    --chips-origin-inactive: #8f8f8f;
    --chips-incomplete: #f8e71c;
    --chips-expired: #ff9595;
    --chips-expired-hover: #fc625d;
    --chips-tocome: #ffc973;
    --chips-tocome-hover: #feb03e;
    --chips-grey-color: #f8f8f8;

    /*
      * Cards
      * ======================================================================== */
    --cards-header-background: #181163;
    --cards-border-radius: 10px;
    --cards-border: 2px solid #ffffff;
    --cards-border-hover: #777777;
    --cards-meta-background: #eef6fb;
    --cards-border-active: #0077cc;
    --cards-row-separator: #d3ecff;
    --cards-padding: 4px 16px 4px 24px;
    --cards-seperator-color: #e6e9ec;
    --cards-seperator-width: 2px;
    --cards-seperator-height: 34px;
    --cards-seperator-margin: -17px;
    --cards-categories-default: #292929;
    --cards-categories-text: #fccc00;
    --cards-categories-audio: #28c0fc;
    --cards-categories-video: #00ffce;
    --cards-details-background: #f2f1f1;
    --cards-filter-1-background: #1a4db0;
    --cards-filter-1-background-hover: #081735;
    --cards-currently-selected: #e6f5ff;

    /*
      * Linear Loader
      * ======================================================================== */
    --loader-linear-background: rgba(74, 158, 218, 0.3);
    --loader-linear-color: #0077cc;

    /*
      * Avatar
      * ======================================================================== */
    --avatar-background-gradient: #0875e4;

    /*
      * Scrollbars
      * ======================================================================== */
    --scroll-thumb-background: #f7f9fb;
    --scroll-thumb-border: #f7f9fb;
    --scroll-thumb-hover-background: #0077cc;
    --scroll-thumb-hover-border: #0077cc;
    --scroll-track-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

    /*
      * Dropzones
      * ======================================================================== */
    --dropzone-background-primary: #d9d9d9;
    --dropzone-background-secondary: #e4e7eb;
    --dropzone-border-primary: #292929;
    --dropzone-border-secondary: #cfcfcf;
    --dropzone-disabled: #292929;
    --dropzone-error: #d0021b;
    --dropzone-error-light: #ffe1e4;
    --dropzone-active: #7ed321;
    --dropzone-active-light: #d0e6f5;

    /*
      * Dark mode
      * ======================================================================== */
    --dm-colors-primary: #181818;
    --dm-colors-secondary: #343434;
    --dm-colors-tertiary: #252525;
    --dm-colors-white: #afafaf;
    --dm-colors-accent: #219ad2;
    --dm-dropzone-error-light: #330005;
    --dm-dropzone-active-light: #0a202e;
    --dm-dropzone-duplicate-light: #ffc973;

    /*
    * padding
    * ======================================================================== */
    --spacing--16: 16px;
    --spacing--6: 6px;
}
