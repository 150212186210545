.buttonContainer {
    position: relative;
    display: inline-block;
}

button {
    &.button {
        font-family: var(--button-font-family);
        text-transform: none;
        font-size: var(--button-font-size);
        padding: 0 1px 1px 0;
        min-width: 33px;
        min-height: 33px;

        &[class*='text'] {
            color: var(--button-text-color);
            background-color: var(--button-text-background);
            font-weight: 700;

            &:hover {
                color: var(--button-text-hover-color);
                background-color: var(--button-text-hover-background);
            }

            &:disabled {
                color: var(--button-text-disabled);
                border-color: var(--button-text-disabled);
            }
        }

        &[class*='contained'] {
            border-radius: 0;
            color: var(--button-contained-color);
            background: linear-gradient(to bottom, var(--button-gradient-light) 0%, var(--button-gradient-dark) 100%);

            &:hover {
                background: linear-gradient(
                    to bottom,
                    var(--button-gradient-light) 0%,
                    var(--button-contained-hover-background) 100%
                );
            }

            &:active {
                background-color: var(--button-contained-hover-background);

                span:last-child {
                    background-color: transparent;
                }
            }

            &:disabled {
                background: var(--button-contained-disabled-background);
                border: 1px solid var(--button-contained-disabled-background);
            }
        }

        &.success {
            background: var(--colors-success);
            border: 1px solid var(--colors-success);
        }
    }
}

.loader {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

body[class='darkmode'] {
    button {
        &.button[class*='text'] {
            color: var(--dm-colors-white);
        }
    }
}
